<template>
  <div class="container-xs px-1 pb-1 md:pt-1">
    <Modal
      id="accountCreationError"
      header="Account Creation Error"
      text="We've encountered an error while trying to create your account."
      :retryFunction="createProfile"
    />
    <h1 class="mb-2 md:mt-1-half">{{ $t('Register') }}</h1>
    <form class="form__small" action>
      <label for="first-name">{{ $t('FirstName') }}</label>
      <input
        id="first-name"
        v-model="registration.firstName"
        type="text"
        :placeholder="$t('FirstNamePlaceholder')"
        :class="{ 'form-error': formErrors.firstName }"
        required
      />
      <p v-if="formErrors.firstName" class="form-error-label">
        First name must be less than 100 characters.
      </p>

      <label for="last-name">{{ $t('LastName') }}</label>
      <input
        id="last-name"
        v-model="registration.lastName"
        type="text"
        :placeholder="$t('LastNamePlaceholder')"
        :class="{ 'form-error': formErrors.lastName }"
        required
      />
      <p v-if="formErrors.lastName" class="form-error-label">
        Last name must be less than 100 characters.
      </p>

      <label for="sex">{{ $t('Sex') }}</label>
      <div class="select">
        <select id="sex" v-model="registration.sex" name="sex" required>
          <option value="" disabled>Select {{ $t('Sex') }}</option>
          <option v-for="(value, key) in sex" :key="key" :value="key">
            {{ value }}
          </option>
        </select>
      </div>

      <label for="dob">{{ $t('DateOfBirth') }}</label>
      <input
        id="dob"
        v-model="registration.dob"
        type="date"
        :placeholder="$t('DateOfBirthPlaceholder')"
        :class="{ 'form-error': formErrors.dob }"
        required
      />
      <p v-if="formErrors.dob" class="form-error-label">
        Must be a valid date (mm/dd/yyyy).
      </p>

      <label for="phone">{{ $t('PhoneNumber') }}</label>
      <input
        id="phone"
        v-model="registration.phone"
        type="text"
        :placeholder="$t('PhoneNumberPlaceholder')"
        :class="{ 'form-error': formErrors.phone }"
        required
        maxlength="10"
      />
      <p v-if="formErrors.phone" class="form-error-label">
        Phone number must be exactly 10 numbers.
      </p>

      <label for="zip">{{ $t('Zip') }}</label>
      <input
        id="zip"
        v-model="registration.zip"
        type="text"
        :placeholder="$t('ZipPlaceholder')"
        required
        maxlength="5"
      />

      <label for="race">Race</label>
      <div class="select">
        <select id="race" v-model="registration.race" name="race" required>
          <option value="" disabled>Select race</option>
          <option v-for="(value, key) in race" :key="key" :value="key">
            {{ value }}
          </option>
        </select>
      </div>

      <label for="ethnicity">Ethnicity</label>
      <div class="select">
        <select
          id="ethnicity"
          v-model="registration.ethnicity"
          name="ethnicity"
          required
        >
          <option value="" disabled>Select ethnicity</option>
          <option v-for="(value, key) in ethnicity" :key="key" :value="key">
            {{ value }}
          </option>
        </select>
      </div>

      <div class="text-center mt-2-half">
        <button
          class="button extra-large mb-1"
          :disabled="!isValid"
          @click.prevent="createProfile"
        >
          {{ $t('Continue') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { isValid } from 'date-fns';

import Modal from '@/components/Modal.vue';

import {
  states,
  sexOptions,
  raceOptions,
  ethnicityOptions,
} from '@/constants.ts';
import { getIdToken } from '@/plugins/auth';

const CONTAINS_NON_NUMERIC = /[^$,.\d]/;

export default Vue.extend({
  name: 'Registration',
  components: {
    Modal,
  },
  data() {
    return {
      registration: {
        firstName: '',
        lastName: '',
        sex: '',
        dob: '',
        phone: '',
        zip: '',
        race: '',
        ethnicity: '',
      },
      formErrors: {
        firstName: false,
        lastName: false,
        dob: false,
        phone: false,
      },
      allStates: states,
    };
  },
  computed: {
    ...mapState(['locale', 'errors']),
    sex() {
      return sexOptions[this.locale];
    },
    race() {
      return raceOptions[this.locale];
    },
    ethnicity() {
      return ethnicityOptions[this.locale];
    },
    isValid() {
      const { firstName, lastName, phone, dob } = this.registration;

      const firstNameValid = firstName.length <= 100;
      const lastNameValid = lastName.length <= 100;
      const phoneValid =
        !phone.match(CONTAINS_NON_NUMERIC) && phone.length === 10;
      const dobValid = dob && isValid(new Date(dob));
      this.updateErrors({
        firstNameValid,
        lastNameValid,
        // hide the error if the field is empty
        phoneValid: phone === '' || phoneValid,
        dobValid: dob === '' || dobValid,
      });

      return firstNameValid && lastNameValid && phoneValid && dobValid;
    },
  },
  methods: {
    updateErrors: function ({
      firstNameValid,
      lastNameValid,
      dobValid,
      phoneValid,
    }) {
      this.formErrors = {
        firstName: !firstNameValid,
        lastName: !lastNameValid,
        dob: !dobValid,
        phone: !phoneValid,
      };
    },
    createProfile: async function () {
      this.$store.dispatch('setActiveModal', null);
      const idToken = await getIdToken();
      await this.$store.dispatch('createProfile', {
        profile: this.registration,
        testingCenter: this.$store.state.currentTestingCenter.id,
        consentVersion: this.$store.state.currentTestingCenterConsent.version,
        time: this.$store.state.currentAppointmentTime.time,
        idToken,
      });
      if (this.errors.userProfile) {
        this.$store.dispatch('setActiveModal', 'accountCreationError');
      } else {
        this.$router.push('/account-created');
      }
    },
  },
});
</script>
