<template>
  <div>
    <Registration />
  </div>
</template>

<script>
import Registration from '@/components/Registration/Registration';

export default {
  name: 'RegistrationPage',
  components: {
    Registration,
  },
};
</script>
